@tailwind base;
@tailwind components;
@tailwind utilities;


.divider{
    height: 0.5px;
    width: 70%;
    background-color: red;
}

body{
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #dcebfb;
}
::-webkit-scrollbar-thumb {
    background: #232c54;
    border-radius: 10px;
}
  


